div .card {
  border-radius: 10px;
  color: white;
  position: relative;
  height: 230px;
  margin: 30px 0px;
}

.card img {
  width: 60px;
  margin-bottom: 5px;
}

.card .training-card-body .card-title {
  font-size: 16px;
}

.card .training-card-body .card-text {
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  position: absolute;
  bottom: 26%;
  left: 0%;
  padding-left: 8%;
}

.card .training-card-body .card-text > :nth-child(1) {
    font-size: 11px;
    font-weight: 100;
}
.card .training-card-body .card-text > :nth-child(2) {
    display: block;
    font-size: 16px;
    font-weight: 600;
}



.card .training-card-body button {
  position: absolute;
  bottom: 3%;
  background-color: white;
  color: black;
  font-weight: 600;
  padding: 2px 40px;
  border-radius: 30px;
  left: 50%;
  transform: translate(-50%, -20%);
}
