.lab-container {
    background-color: black;
    padding: 5% 0%;
}

.lab-container .lab-row-container .left-sec h1 {
    font-size: 60px;
    color: red;
    width: 80%;
    text-align: center;
    margin: 0px auto;
    margin-bottom: 20px;
}

.lab-container .lab-row-container .left-sec p {
    color: white;
    font-size: 18px;
}

.lab-container .right-sec img {
    width: 90%;
    float: right;
}

.lab-container .lab-row-container {
    margin: 0 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lab-container .lab-row-container .left-sec .btn-container {
    text-align: center;
}

.lab-container .lab-row-container .left-sec .btn-container button {
    padding: 5px 15px;
    background-color: red;
    color: white;
    font-size: 22px;
    border-radius: 30px;
    margin-top: 20px;
}

.lab-container .lab-row-container .left-sec .btn-container button .bi {
    margin-left: 8px;
}

.lab-container .lab-row-container .left-sec .btn-container button:nth-of-type(2) {
    margin-left: 15px;
}


@media (max-width: 400px) {
    .lab-container .lab-row-container .left-sec h1 {
        font-size: 40px;
    }
    .lab-container .lab-row-container .left-sec p {
        font-size: 14px;
    }
    .lab-container .lab-row-container .left-sec .btn-container button {
        padding: 5px 10px;
        font-size: 18px;
    }
    
}