.thumbnail-container {
  display: flex;
}

.thumbnail-container .card {
  width: 300px;
  height: 200px;
  border-radius: 0%;
  text-align: left;
  position: relative;
  border: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.3);
  background-blend-mode: darken;
}

.thumbnail-container .card .thumbnail-card-body > .card-title {
  font-size: 16px;
  color: yellow;
  margin: 0;
}

.thumbnail-container .card .thumbnail-card-body > .card-text {
  font-size: 16px;
}

.thumbnail-container .card .thumbnail-card-body {
  padding: 10px;
  position: absolute;
  bottom: 0%;
}
