.buttonGroupToggle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0px;
}

.buttonGroupToggle-container .btn-group > button {
    border: none;
    border-radius: 0;
    height: 46px;
}


.buttonGroupToggle-container .btn-group .bgColor-white {
    color: white;
    background-color: black;
}

.buttonGroupToggle-container .btn-group .bgColor-black {
    color: black;
    background-color: white;
}



.buttonGroupToggle-container > .btn-group {
    margin-right: 20px;
    border: 1px solid black;
}

.buttonGroupToggle-container .btn-group > button {
    font-size: 22px;
}
